import { Dialog } from "@headlessui/react";
import styled from "styled-components";

export const DialogBackground = styled.div`
  z-index: 1000;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
`;

export const DialogBlock = styled(Dialog)`
  z-index: 1001;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: none;
  outline: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
`;

export const CloseModalButton = styled.button`
  position: absolute;
  width: 14px;
  height: 14px;
  border: none;
  top: 14px;
  right: 10px;
  background: url("/Close_Modal.svg");
  cursor: pointer;
  z-index: 10;
`;

export const Chip = styled.div`
  border-radius: 120px;
  border: 1px solid ${({ theme }) => theme.color.Red};
  padding: 3.5px 21.5px;
  box-sizing: border-box;
`;
