import theme from "config/theme";
import styled from "styled-components";

export const H0 = styled.h1<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[48]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 72px;
  margin: 0px;
`;

export const H1 = styled.h1<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[40]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 60px;
  margin: 0px;
`;

export const H2 = styled.h2<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[32]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 41.6px;
  margin: 0px;
`;

export const H3 = styled.h3<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[28]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 36.4px;
  margin: 0px;
`;

export const H4 = styled.h3<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[24]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 31.2px;
  margin: 0px;
`;

export const T1 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[24]};
  font-weight: 600;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 36px;
  margin: 0px;
`;

export const T2 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[24]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 36px;
  margin: 0px;
`;

export const T3 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[20]};
  font-weight: 700;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 28px;
  margin: 0px;
`;

export const T4 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[20]};
  font-weight: 600;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 28px;
  margin: 0px;
`;

export const Body1 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[20]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 28px;
  margin: 0px;
`;

export const Body2 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[18]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 25.2px;
  margin: 0px;
`;

export const Body3 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[16]};
  font-weight: 600;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 24px;
  margin: 0px;
`;

export const Body4 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[16]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 24px;
  margin: 0px;
`;

export const Body5 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[15]};
  font-weight: 400;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 24px;
  margin: 0px;
`;

export const Caption1 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[15]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 22.5px;
  margin: 0px;
`;

export const Caption2 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[14]};
  font-weight: 600;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 21px;
  margin: 0px;
`;

export const Caption3 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[13]};
  font-weight: 600;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 19.5px;
  margin: 0px;
`;

export const Caption4 = styled.p<{ color?: keyof typeof theme.color }>`
  font-size: ${({ theme }) => theme.fontSize[13]};
  font-weight: 500;
  color: ${({ theme, color }) => theme.color[color ?? "black"]};
  letter-spacing: -2.2%;
  line-height: 19.5px;
  margin: 0px;
`;

export const Wrap = styled.div<{
  background?: string;
  padding?: string;
  gap?: string;
  height?: string;
  minheight?: string;
  width?: string;
  direction?: string;
  align?: string;
  jutify?: string;
  position?: string;
}>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "0px")};
  width: ${(props) => (props.width ? props.width : "auto")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  gap: ${(props) => (props.gap ? props.gap : "0px")};
  height: ${(props) => (props.height ? props.height : "auto")};
  min-height: ${(props) => (props.minheight ? props.minheight : "auto")};
  flex-direction: ${(props) => (props.direction ? props.direction : "row")};
  justify-content: ${(props) => (props.jutify ? props.jutify : "flex-start")};
  background: ${(props) => (props.background ? props.background : "none")};
  position: ${(props) => (props.position ? props.position : "static")};
`;

export const Button = styled.button<{
  width?: string;
  height?: string;
  background?: keyof typeof theme.color;
}>`
  border-radius: 8px;
  background: ${({ theme, background }) => theme.color[background ?? "Black"]};
  width: ${(props) => (props.width ? props.width : "auto")};
  height: ${(props) => (props.height ? props.height : "auto")};
  border: none;
  cursor: pointer;
`;
