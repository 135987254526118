import React from "react";

import {
  Chip,
  CloseModalButton,
  DialogBackground,
  DialogBlock,
} from "layout/styles";
import { Body3, Caption2, Wrap } from "components/Common";
import Spacing from "components/Common/Spacing";
import { Checkbox } from "components/Common/Checkbox";
import { setCookie } from "utils/cookie";

type InvalidWindowSizePopupProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

function InvalidWindowSizePopup({
  isOpen,
  setIsOpen,
}: InvalidWindowSizePopupProps) {
  return (
    <DialogBackground>
      <DialogBlock open={isOpen} onClose={() => setIsOpen(false)}>
        <CloseModalButton onClick={() => setIsOpen(false)} />
        <Wrap width="320px" height="430px" direction="column">
          <Spacing height="62px" />
          <Chip>
            <Body3 color="Red">PC 접속 추천</Body3>
          </Chip>
          <Spacing height="34px" />
          <img src="/Computer.svg" alt="Computer" />
          <Spacing height="15px" />
          <Body3>Checkmate는 PC 환경에 최적화된</Body3>
          <Body3>서비스입니다. 보다 편리한 이용을 위해</Body3>
          <Body3>PC에서 접속을 권장합니다.</Body3>
          <Spacing height="34px" />
          <Wrap
            align="center"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(false);
              setCookie("invalidWindowSizePopup", "true", 1);
            }}
          >
            <Checkbox />
            <Caption2 style={{ whiteSpace: "nowrap" }} color="Gray50">
              24시간 동안 보지 않기
            </Caption2>
          </Wrap>
          <Spacing height="38px" />
        </Wrap>
      </DialogBlock>
    </DialogBackground>
  );
}

export default InvalidWindowSizePopup;
