import styled from "styled-components";

export const CheckboxContainer = styled.div<{
  width?: string | number;
  display?: boolean;
}>`
  display: ${({ display }) => (display ? "none" : "block")};
  input {
    display: none;
  }

  label {
    display: block;
    width: 16px;
    height: 16px;
    background-size: contain;
    cursor: pointer;
  }

  .check + label {
    background-repeat: no-repeat;
    background-image: url("/Checkbox.svg");
  }

  .check:checked + label {
    background-repeat: no-repeat;
    background-image: url("/Checkbox_selected.svg");
  }
`;
