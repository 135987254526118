import React from "react";
import { type PropsWithChildren } from "react";
import type { NavigateProps } from "react-router-dom";
import { Navigate } from "react-router-dom";

import SwitchCase from "components/Common/SwitchCase";

import { useRecoilState } from "recoil";
import { userSelectorFamily } from "recoil/login";

const AdminRequired = ({
  children,
  to = "/",
  state,
  relative,
}: PropsWithChildren<
  Omit<NavigateProps, "to"> & { to?: NavigateProps["to"] }
>) => {
  const [isAdmin] = useRecoilState(userSelectorFamily("isAdmin"));
  return (
    <SwitchCase
      value={isAdmin}
      caseBy={{
        true: <>{children}</>,
        false: <Navigate to={to} replace state={state} relative={relative} />,
      }}
    />
  );
};

export default AdminRequired;
