import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import React, { Suspense, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { createRoot } from "react-dom/client";
import packageJson from "../package.json";
import { RecoilRoot } from "recoil";
import { GlobalStyle } from "./components/styles";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import QueryClient from "react-query/QueryClient";
import QueryClientProvider from "react-query/QueryClientProvider";
// import { worker } from "./mocks";
import { ThemeProvider } from "styled-components";
import theme from "config/theme";
import LoginRequired from "hocs/LoginRequired";
import NonLoginRequired from "hocs/NonLoginRequired";
import AdminRequired from "hocs/AdminRequired";
import InvalidWindowSizePopup from "layout/InvalidWindowSizePopup";
import { getCookie } from "utils/cookie";
// 이외에 필요한 스타일 파일이 있다면 import 해주세요.

console.log(
  `%cNE(O)RDINARY Project Name : ${packageJson.name}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Version : ${packageJson.version}`,
  "color:yellow"
);
console.log(
  `%cNE(O)RDINARY Project Made by : ${packageJson.author}`,
  "color:yellow"
);

console.log(`
███╗   ██╗███████╗ ██╗ ██████╗ ██╗ ██████╗ ██████╗ ██╗███╗   ██╗ █████╗ ██████╗ ██╗   ██╗
████╗  ██║██╔════╝██╔╝██╔═══██╗╚██╗██╔══██╗██╔══██╗██║████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝
██╔██╗ ██║█████╗  ██║ ██║   ██║ ██║██████╔╝██║  ██║██║██╔██╗ ██║███████║██████╔╝ ╚████╔╝
██║╚██╗██║██╔══╝  ██║ ██║   ██║ ██║██╔══██╗██║  ██║██║██║╚██╗██║██╔══██║██╔══██╗  ╚██╔╝
██║ ╚████║███████╗╚██╗╚██████╔╝██╔╝██║  ██║██████╔╝██║██║ ╚████║██║  ██║██║  ██║   ██║
╚═╝  ╚═══╝╚══════╝ ╚═╝ ╚═════╝ ╚═╝ ╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝
`);

const loading = (
  <div
    style={{
      width: "100%",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(100vh - 419px)",
    }}
  >
    <ClipLoader
      color={"#FF2D2E"}
      size="85px"
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

// Pages
const Home = React.lazy(() => import("./pages/home"));
const Login = React.lazy(() => import("./pages/login"));
const Page404 = React.lazy(() => import("./pages/page404/Page404"));
const Page500 = React.lazy(() => import("./pages/page500/Page500"));
const AdminLogin = React.lazy(() => import("./pages/login/admin"));
const KakaoLogin = React.lazy(() => import("./pages/login/kakaologin"));
const RoomListPage = React.lazy(() => import("./pages/room/list"));
const RoomCreatePage = React.lazy(() => import("./pages/room/edit"));
const RegisterPage = React.lazy(() => import("./pages/register"));
const ContractPage = React.lazy(() => import("./pages/contract"));
const ContractedPage = React.lazy(() => import("./pages/contract/contracted"));
const GuidePage = React.lazy(() => import("./pages/guide"));
const ProfileEditPage = React.lazy(() => import("./pages/profile/edit"));
const DashboardPage = React.lazy(() => import("./pages/dashboard"));
const UserManagePage = React.lazy(() => import("./pages/user/manage"));

const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: Infinity } },
});

// worker.start();

root.render(
  <QueryClientProvider queryClient={queryClient}>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense fallback={loading}>
          <App />
        </Suspense>
      </ThemeProvider>
    </RecoilRoot>
  </QueryClientProvider>
);

function App() {
  const [open, setOpen] = React.useState(
    (getCookie("invalidWindowSizePopup") || "true") !== "true"
  );

  const handleResize = () => {
    if (
      window.innerWidth < 800 &&
      getCookie("invalidWindowSizePopup") !== "true"
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {open && <InvalidWindowSizePopup isOpen={open} setIsOpen={setOpen} />}
      <RouterProvider
        router={createBrowserRouter([
          {
            path: "/",
            element: <DefaultLayout />,
            children: [
              {
                path: "/",
                element: <Home />,
              },
              {
                path: "/room",
                element: (
                  <LoginRequired to="/login">
                    <RoomListPage />
                  </LoginRequired>
                ),
              },
              {
                path: "/room/create/:id?",
                element: (
                  <LoginRequired to="/login">
                    <RoomCreatePage />
                  </LoginRequired>
                ),
              },
              {
                path: "/contract",
                element: (
                  <LoginRequired to="/login">
                    <ContractPage />
                  </LoginRequired>
                ),
              },
              {
                path: "/contracted",
                element: (
                  <LoginRequired to="/login">
                    <ContractedPage />
                  </LoginRequired>
                ),
              },
              {
                path: "/guide",
                element: <GuidePage />,
              },
              {
                path: "/profile/edit",
                element: (
                  <LoginRequired to="/login">
                    <ProfileEditPage />
                  </LoginRequired>
                ),
              },
              {
                path: "/dashboard",
                element: (
                  <AdminRequired to="/">
                    <DashboardPage />
                  </AdminRequired>
                ),
              },
              {
                path: "/user/manage",
                element: (
                  <AdminRequired to="/">
                    <UserManagePage />
                  </AdminRequired>
                ),
              },
            ],
          },
          {
            path: "/login",
            element: (
              <NonLoginRequired>
                <Login />
              </NonLoginRequired>
            ),
          },
          {
            path: "/register",
            element: (
              <NonLoginRequired>
                <RegisterPage />
              </NonLoginRequired>
            ),
          },
          {
            path: "/admin/login",
            element: (
              <NonLoginRequired>
                <AdminLogin />
              </NonLoginRequired>
            ),
          },
          {
            path: "/kakaologin",
            element: <KakaoLogin />,
          },
          {
            path: "/404",
            element: <Page404 />,
          },
          {
            path: "/500",
            element: <Page500 />,
          },
        ])}
      />
    </>
  );
}
