const fontSize = {
  48: "48px",
  40: "40px",
  32: "32px",
  28: "28px",
  24: "24px",
  20: "20px",
  18: "18px",
  16: "16px",
  15: "15px",
  14: "14px",
  13: "13px",
};

const color = {
  Red: "rgba(255, 45, 46, 1)",
  PastelRed: "rgba(255, 243, 243, 1)",
  White: "rgba(255, 255, 255, 1)",
  Black: "rgba(0, 0, 0, 1)",
  Gray50: "rgba(79, 79, 79, 1)",
  Gray40: "rgba(128, 137, 145, 1)",
  Gray30: "rgba(190, 190, 190, 1)",
  Gray20: "rgba(236, 236, 236, 1)",
  Gray10: "rgba(250, 250, 250, 1)",
  Gray0: "rgba(248, 248, 248, 1)",
  Blue: "rgba(9, 0, 255, 1)",
  ErrorRed: "rgba(232, 63, 46, 1)",
  bg: "rgba(248, 248, 248, 1)",
};

const theme = {
  fontSize,
  color,
};

export default theme;
