import React, { forwardRef, InputHTMLAttributes } from "react";
import { CheckboxContainer } from "./styles";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  display?: boolean;
};

export const Checkbox =
  // eslint-disable-next-line react/display-name
  forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    return (
      <CheckboxContainer display={props.display}>
        <input ref={ref} {...props} type="checkbox" className="check" />
        <label htmlFor="check" style={{ width: "16px", height: "16px" }} />
      </CheckboxContainer>
    );
  });
