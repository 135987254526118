import React, { type CSSProperties, type PropsWithChildren } from "react";

type BoxType = PropsWithChildren<
  {
    as?: "div" | "li";
    className?: string;
    onClick?: () => void;
  } & CSSProperties
>;
const Box = ({
  children,
  as: Component = "div",
  className,
  onClick,
  ...style
}: BoxType) => {
  return (
    <Component style={style} onClick={onClick} className={className}>
      {children}
    </Component>
  );
};

export default Box;
